import {
  lockBodyScroll,
  unlockBodyScroll
} from '@/shared/utils/scroll/body-scroll';

const lockedAttribute = 'data-scroll-locked-class';

const addClassIfNotPresent = (element: HTMLElement, className: string) => {
  if (element.classList.contains(className)) {
    return false;
  }

  element.classList.add(className);
  return true;
};

const addNotPresentClasses = (element: HTMLElement, classNames: string[]) => {
  const addedClasses: string[] = [];
  classNames.forEach((className) => {
    if (addClassIfNotPresent(element, className)) {
      addedClasses.push(className);
    }
  });

  return addedClasses;
};

export const isLocked = (element: HTMLElement) =>
  element.hasAttribute(lockedAttribute);

export const lockScroll = (
  element: HTMLElement | null,
  overflowType: 'hidden' | 'clip' = 'hidden'
) => {
  if (!element || isLocked(element)) {
    return;
  }

  const addedClasses = addNotPresentClasses(element, [
    `overflow-${overflowType}`,
    // Touch scroll prevention
    'touch-pinch-zoom',
    'scrolling-touch'
  ]);

  element.setAttribute(lockedAttribute, addedClasses.join(' '));
};

export const unlockScroll = (element: HTMLElement | null) => {
  if (!element || !isLocked(element)) {
    return;
  }

  const addedClassName = element.getAttribute(lockedAttribute);
  if (addedClassName) {
    element.classList.remove(...addedClassName.split(' '));
  }

  if (element.classList.length === 0) {
    element.removeAttribute('class');
  }

  element.removeAttribute(lockedAttribute);
};

export const lockAllParentsScroll = (
  element: HTMLElement | null,
  overflowType: 'hidden' | 'clip' = 'hidden'
) => {
  if (!element) {
    return;
  }

  let parent = element?.parentElement;
  while (parent && parent.tagName !== 'BODY') {
    lockScroll(parent, overflowType);
    parent = parent.parentElement;
  }
  lockBodyScroll();
};

export const unlockAllParentsScroll = (element: HTMLElement | null) => {
  if (!element) {
    return;
  }

  let parent = element?.parentElement;
  while (parent && parent.tagName !== 'BODY') {
    unlockScroll(parent);
    parent = parent.parentElement;
  }
  unlockBodyScroll();
};

export const unlockAllLockedElementsScroll = () => {
  const lockedElements = document.querySelectorAll<HTMLElement>(
    `[${lockedAttribute}]`
  );
  lockedElements.forEach((lockedElement) => unlockScroll(lockedElement));
  unlockBodyScroll();
};
