import { stickyHeaderHeadroom } from '@/shared/instances/sticky-header';

const lockedScrollYAttribute = 'data-scroll-locked-y';

const headerSelector = '.js-sticky-header-wrapper';
const headerScrollLockClasses = ['fixed', 'w-full', 'top-0'];

const bodyScrollLockClasses = ['fixed', 'w-full'];

export const lockBodyScroll = () => {
  const body = document.body;
  const stickyHeader = document.querySelector<HTMLElement>(headerSelector);

  const scrollY = window.scrollY;
  body.setAttribute(lockedScrollYAttribute, String(scrollY));

  stickyHeaderHeadroom?.freeze();
  stickyHeader?.classList.add(...headerScrollLockClasses);

  body.style.top = `-${scrollY}px`;
  body.classList.add(...bodyScrollLockClasses);
};

export const unlockBodyScroll = async () => {
  const body = document.body;
  const stickyHeader = document.querySelector<HTMLElement>(headerSelector);

  const scrollY = parseInt(body.getAttribute(lockedScrollYAttribute) || '0');
  body.removeAttribute(lockedScrollYAttribute);

  body.classList.remove(...bodyScrollLockClasses);
  if (body.classList.length === 0) {
    body.removeAttribute('class');
  }

  body.style.top = '';
  if (!body.getAttribute('style')) {
    body.removeAttribute('style');
  }

  window.scrollTo({ top: scrollY });

  stickyHeader?.classList.remove(...headerScrollLockClasses);
  if (stickyHeader?.classList.length === 0) {
    stickyHeader?.removeAttribute('class');
  }

  // wait one tick before unfreezing to maintain header position
  setTimeout(() => stickyHeaderHeadroom?.unfreeze());
};
