<template>
  <AppModal
    ref="tocVerificationModal"
    transition="slide-bottom-fade"
    class-name="toc-confirmation-modal"
    @on-close="closeModal"
  >
    <template #header> </template>
    <template #body>
      <h1 class="mb-3 text-center text-2xl font-bold">
        {{ t('toc.text_title') }}
      </h1>
      <div class="mb-3 text-base">{{ t('toc.text_description') }}</div>
      <div class="mb-2 flex text-base">
        <input
          id="toc-agree"
          v-model="tocAgree"
          type="checkbox"
          class="relative top-1 m-0 !mr-2 size-5 cursor-pointer p-0"
        />
        <i18n-t
          keypath="toc.text_agree"
          tag="label"
          for="toc-agree"
          class="flex-1 cursor-pointer"
        >
          <a class="underline" :href="TOCLink" target="_blank">{{
            t('toc.label')
          }}</a>
        </i18n-t>
      </div>
      <button
        class="rounded-lg border-0 bg-primary py-3 text-base font-bold text-white transition"
        :class="{ 'pointer-events-none opacity-50': tocLoading || !tocAgree }"
        @click="onContinueClick"
      >
        {{ t('toc.text_continue') }}
      </button>
      <ImageHappyYarnGuy class="m-auto mt-6" />
    </template>
  </AppModal>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useUserStore } from '../../pinia/modules/user';
import { useConfigStore } from '../../pinia/modules/config';
import AppModal from '../Legacy/AppModal.vue';
import ImageHappyYarnGuy from '../Images/ImageHappyYarnGuy.vue';
import { computed } from 'vue';
import { useTranslations } from '@/shared/composables/useTranslations.ts';

const { t } = useTranslations();
const userStore = useUserStore();

const tocVerificationModal = ref(null);
const tocAgree = ref(false);
const tocLoading = ref(false);

const TOCLink = computed(() => {
  const configStore = useConfigStore();
  return configStore.tocPath;
});

watch(
  () => userStore.showTocAgree,
  () => {
    if (userStore.showTocAgree) {
      tocVerificationModal.value.openModal();
    } else {
      tocVerificationModal.value.closeModal();
    }
  }
);

const onContinueClick = () => {
  if (tocAgree.value) {
    tocLoading.value = true;
    userStore.acceptToc({ onSuccess: closeModal, onError });
  } else {
    userStore.showTocAgree = false;
  }
};

const onError = () => {
  tocLoading.value = false;
};

const closeModal = () => {
  tocLoading.value = false;
  userStore.showTocAgree = false;
};
</script>
