<template>
  <div
    ref="notificationsContainer"
    class="relative h-[calc(100vh-64px)] overflow-auto"
    :class="{ 'pb-[90px]': showLoaderSpace }"
  >
    <Transition name="slide-fade">
      <div v-if="visibleUnread" class="max-h-[39px] text-right">
        <span
          class="inline-block cursor-pointer px-4 py-2 text-xs font-bold text-primary"
          @click="onMarkAllAsReadClick"
          >{{ $t('text_mark_as_read') }}</span
        >
      </div>
    </Transition>
    <div
      v-if="notifications.length"
      ref="notificationsList"
      class="px-2.5 pb-1 pt-0"
    >
      <NotificationItem
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
        :notification-url="notificationsStore.notificationUrl(notification)"
        @click.left="($event) => onClick($event, notification)"
        @click.middle.exact="($event) => onClick($event, notification)"
      />
    </div>
    <div
      v-if="notifications.length == 0 && !showLoader"
      class="px-4 py-12 text-center"
    >
      {{ $t('text_no_notifications') }}
    </div>
    <div v-if="showLoader" class="loader absolute left-1/2 -ml-6"></div>
  </div>
</template>

<script setup>
import {
  onMounted,
  onBeforeUnmount,
  computed,
  ref,
  watch,
  nextTick
} from 'vue';
import { debounce } from '../../utils/functions';
import { useNotificationsStore } from '../../pinia/modules/notifications';
import NotificationItem from './NotificationItem.vue';

const notificationsStore = useNotificationsStore();

const emit = defineEmits(['onListClose']);

const notificationsContainer = ref(null);
const notificationsList = ref(null);
const showLoader = ref(true);
const showLoaderSpace = computed(() => notificationsStore.nextPageAvaialble);
const notifications = computed(() => notificationsStore.notifications);
const visibleUnread = computed(() => notificationsStore.visibleUnread);

onMounted(() => {
  notificationsStore.getNotifications();

  notificationsContainer.value.addEventListener('scroll', onScroll);
  notificationsStore.setNotificationsAsSeen();
});

const onScroll = debounce(() => {
  if (
    notificationsList.value.getBoundingClientRect().bottom -
      window.innerHeight <
    200
  ) {
    showMore();
  }
}, 300);

notificationsStore.$subscribe((mutation) => {
  if (mutation.storeId == 'notifications') {
    showLoader.value = false;
  }
});

watch(
  () => notifications.value,
  () => {
    nextTick(() => {
      if (
        notificationsList.value?.offsetHeight -
          notificationsContainer.value?.offsetHeight <
        -10
      ) {
        showMore();
      }
    });
  }
);

const showMore = () => {
  if (!notificationsStore.nextPageAvaialble) return;

  showLoader.value = true;
  notificationsStore.getNotifications(true);
};

const onClick = (event, notification) => {
  event.preventDefault();

  const isMiddle = event.which == 2;
  notificationsStore.goToNotificationTarget({
    notification,
    isShift: event.shiftKey,
    isCtrl: event.ctrlKey,
    isCmd: event.metaKey,
    isMiddle
  });

  if (!event.shiftKey && !event.ctrlKey && !event.metaKey && !isMiddle) {
    emit('onListClose');
  }
};

const onMarkAllAsReadClick = () => {
  notificationsStore.markAllAsRead();
};

onBeforeUnmount(() => {
  notificationsStore.notifications = [];
  notificationsContainer.value.removeEventListener('scroll', onScroll);
});
</script>
