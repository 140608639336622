import { v5 as uuid5 } from 'uuid';
import { getCookie, setCookie } from '../utils/cookie';

const HOBBII_NAMESPACE = '64525bc0-03a1-4d1f-b342-1f53a5061697';
const hobbiiStoreData = window.Hobbii?.hobbiiStoreData;
const initGtmTracking = () => {
  updateCurrencyRateCookie();
  updateContactUuidCookie();
};

function updateContactUuidCookie() {
  if (hobbiiStoreData.isLogged) {
    saveContactUuidCookie(hobbiiStoreData?.customer?.email);
  } else if (getCookie('contact_uuid')) {
    setCookie('contact_uuid', '', -1);
  }
}

function saveContactUuidCookie(email) {
  if (!email) return;

  // This cookie is read by GTM and used in Emarsys conversion tracking
  setCookie('contact_uuid', generateContactUuid(email));
}

function generateContactUuid(email) {
  return uuid5(email, HOBBII_NAMESPACE);
}

function updateCurrencyRateCookie() {
  saveCurrencyRateCookie(hobbiiStoreData?.currencyRate);
}

function saveCurrencyRateCookie(currencyRate) {
  if (!currencyRate) return;

  // This cookie is read by GTM and used in Emarsys conversion tracking
  setCookie('currency_rate', currencyRate);
}

export { initGtmTracking };
