import Autocomplete from '@/shared/components/Shopify/Autocomplete.vue';
import { breakpoints } from '@/shared/constants/breakpoints';
import { debounce } from '@/shared/utils/functions.js';
import { setupI18n } from '@/shared/utils/i18n';
import { createApp } from 'vue';

const debugEnabled = window.location.search.includes('debug_autocomplete');
const desktopSearchName = 'header-searchbox';
const mobileSearchName = 'header-searchbox-mobile';
let activeSearch;
let SearchInstance;

const createVueInstance = async (name) => {
  const i18n = await setupI18n(window.Shopify.locale);
  const el = document.getElementById(name);
  if (!el) {
    if (debugEnabled) {
      console.info(
        `No element found for id "${name}", aborting autocomplete init.`
      );
    }
    return;
  }

  const { searchTerms, inputId } = el.dataset;

  SearchInstance = createApp(Autocomplete, {
    searchTerms,
    inputId
  });
  SearchInstance.use(window.hobbiiStore).use(i18n).mount(el);
};

export const initAutoComplete = () => {
  const windowWidth = window.innerWidth;
  if (windowWidth < breakpoints.lg) {
    if (activeSearch !== mobileSearchName) {
      activeSearch = mobileSearchName;
      if (SearchInstance) {
        SearchInstance.unmount();
      }

      createVueInstance(mobileSearchName);
    }
  } else {
    if (activeSearch !== desktopSearchName) {
      activeSearch = desktopSearchName;
      if (SearchInstance) {
        SearchInstance.unmount();
      }
      createVueInstance(desktopSearchName);
    }
  }
};

const initAutoCompleteDebounced = debounce(initAutoComplete, 400);

window.addEventListener('resize', initAutoCompleteDebounced);
