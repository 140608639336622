import Headroom from 'headroom.js';
import { breakpoints } from '../constants/breakpoints';

export let stickyHeaderHeadroom: Headroom | null = null;

const initStickyHeader = () => {
  // return if the screen is tablet or larger
  const mediaQueryList = window.matchMedia(`(min-width: ${breakpoints.lg}px)`);

  const stickyHeaderWrapper = document.querySelector<HTMLElement>(
    '.js-sticky-header-wrapper'
  );
  const options = {
    offset: 200,
    tolerance: {
      up: 10,
      down: 0
    },
    classes: {
      initial:
        'max-lg:transition-transform max-lg:duration-300 max-lg:ease-out',
      unpinned: 'max-lg:-translate-y-full',
      pinned: 'max-lg:translate-y-0',
      top: 'max-lg:translate-y-0',
      bottom: 'max-lg:!translate-y-0'
    }
  };

  // Define a callback function for the event listener.
  const handleResize = (mql: MediaQueryListEvent) => {
    if (!stickyHeaderWrapper) return;
    if (mql.matches) {
      stickyHeaderHeadroom?.destroy();
      stickyHeaderHeadroom = null;
    } else {
      stickyHeaderHeadroom = new Headroom(stickyHeaderWrapper, options);
      stickyHeaderHeadroom.init();
    }
  };

  handleResize(
    new MediaQueryListEvent('change', { matches: mediaQueryList.matches })
  );

  mediaQueryList.addEventListener('change', handleResize);
};

export { initStickyHeader };
