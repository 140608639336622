<template>
  <div
    v-if="customer"
    class="flex size-12 min-w-12 cursor-auto items-center justify-center rounded-full bg-gray-lightest font-bold text-black"
  >
    <template v-if="customer.avatar_url">
      <img
        :src="customer.avatar_url + '?w=' + size + '&auto=compress'"
        :alt="customerName"
        class="size-full rounded-full"
      />
    </template>
    <template v-else>
      {{ initials }}
    </template>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  customer: {
    type: Object,
    required: true
  },
  size: {
    type: String,
    default: '100'
  }
});

const customerName = computed(() =>
  props.customer.display_name
    ? props.customer.display_name
    : props.customer.first_name
);
const initials = computed(() =>
  props.customer.display_name
    ? props.customer.display_name.charAt(0).toUpperCase()
    : props.customer.first_name.charAt(0).toUpperCase()
);
</script>
