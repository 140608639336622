<template>
  <span
    v-if="userStore.isLogged"
    class="flex cursor-pointer flex-col items-center justify-center gap-px text-center text-sm md:gap-1"
    :title="$t('text_notifications')"
    @click="onClick"
  >
    <span class="relative">
      <IconBellRings
        v-if="unseen"
        class="text-xl text-white md:text-3xl md:text-gray-darker"
      />
      <IconBell
        v-else
        class="text-xl text-white md:text-3xl md:text-gray-darker"
      />
      <span
        v-if="unseen"
        class="absolute -right-2 -top-1 flex h-5 min-w-5 items-center justify-center rounded-full bg-pink p-1 text-xxs font-bold leading-none text-white md:-right-2 md:-top-3 md:h-6 md:min-w-6 md:text-xs"
        >{{ unseen }}</span
      >
    </span>
    <span class="text-xxs text-white md:text-sm md:text-gray-darkest">{{
      $t('text_notifications')
    }}</span>
  </span>

  <AppModal
    ref="notificationsModal"
    transition="slide-right-fade"
    class-name="notifications-modal"
  >
    <template #header>
      <h1>{{ $t('text_notifications') }}</h1>
    </template>
    <template #body>
      <NotificationsList @on-list-close="notificationsModal.closeModal()" />
    </template>
  </AppModal>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useNotificationsStore } from '../../pinia/modules/notifications';
import { useUserStore } from '../../pinia/modules/user';
import AppModal from '../Legacy/AppModal.vue';
import { openLoginModal } from '../../instances/login-modal';
import NotificationsList from './NotificationsList.vue';
import IconBell from '../Icons/IconBell.vue';
import IconBellRings from '../Icons/IconBellRings.vue';

const notificationsStore = useNotificationsStore();
const userStore = useUserStore();

const notificationsModal = ref(null);
const unseen = computed(() =>
  notificationsStore.unseen > 99 ? '99+' : notificationsStore.unseen
);

const onClick = () => {
  if (userStore.isLogged) {
    notificationsModal.value.openModal();
  } else {
    openLoginModal();
  }
};
</script>
