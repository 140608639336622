const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), timeout);
  };
};

const debouncePromise = (fn, time) => {
  let timerId = null;
  let resolveFns = [];

  return function debounced(...args) {
    clearTimeout(timerId);

    timerId = setTimeout(() => {
      resolveFns.forEach((resolve) => resolve(fn(...args)));
      resolveFns = [];
    }, time);

    return new Promise((resolve) => resolveFns.push(resolve));
  };
};

export { debounce, debouncePromise };
