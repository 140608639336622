import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';

import GlobalKeyDownDirective from '../directives/globalKeyDown';
import DateFormatDirective from '../directives/dateFormat';

import Notifications from '../components/Notifications/Notifications.vue';

const initNotifications = async () => {
  const mountId = 'notifications-app';
  const script = document.querySelector(`#${mountId}`);

  if (!script) return;

  let messages = {};
  if (script.dataset.translations) {
    messages = JSON.parse(script.dataset.translations);
  }

  const i18n = createI18n({
    legacy: false, // set to `false` to use Composition API
    locale: 'en',
    fallbackLocale: 'en',
    globalInjection: true,
    messages: {
      en: { ...messages }
    }
  });

  const notificationsAppInstance = createApp(Notifications);

  notificationsAppInstance
    .use(window.hobbiiStore)
    .use(i18n)
    .directive('date-format', DateFormatDirective)
    .directive('global-keydown', GlobalKeyDownDirective)
    .mount(`#${mountId}`);
};

export { initNotifications };
