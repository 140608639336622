<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <g fill="currentColor">
      <path
        d="M5.85 3.5a.75.75 0 0 0-1.117-1a9.719 9.719 0 0 0-2.348 4.876a.75.75 0 0 0 1.479.248A8.219 8.219 0 0 1 5.85 3.5Zm13.417-1a.75.75 0 1 0-1.118 1a8.22 8.22 0 0 1 1.987 4.124a.75.75 0 0 0 1.48-.248a9.72 9.72 0 0 0-2.35-4.876Z"
      />
      <path
        fill-rule="evenodd"
        d="M12 2.25A6.75 6.75 0 0 0 5.25 9v.75a8.217 8.217 0 0 1-2.119 5.52a.75.75 0 0 0 .298 1.206c1.544.57 3.16.99 4.831 1.243a3.75 3.75 0 1 0 7.48 0a24.583 24.583 0 0 0 4.83-1.244a.75.75 0 0 0 .298-1.205a8.217 8.217 0 0 1-2.118-5.52V9A6.75 6.75 0 0 0 12 2.25ZM9.75 18c0-.034 0-.067.002-.1a25.05 25.05 0 0 0 4.496 0l.002.1a2.25 2.25 0 1 1-4.5 0Z"
        clip-rule="evenodd"
      />
    </g>
  </svg>
</template>
