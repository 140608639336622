import { createApp } from 'vue';
import { setupI18n } from '@/shared/utils/i18n';

import GlobalKeyDownDirective from '../directives/globalKeyDown';
import TocVerification from '../components/Toc/TocVerification.vue';

const initTocVerification = async () => {
  const mountId = '#toc-verification';
  if (!document.querySelector(mountId)) {
    console.error(`Element with id: "${mountId}" not found.`);
    return;
  }

  const i18n = await setupI18n(window.Shopify.locale);

  const tocApp = createApp(TocVerification);

  tocApp
    .use(window.hobbiiStore)
    .use(i18n)
    .directive('global-keydown', GlobalKeyDownDirective)
    .mount(mountId);
};

export { initTocVerification };
