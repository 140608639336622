<template>
  <p
    v-date-format:[{t:$t,countryCode:configStore.language}]="date"
    class="whitespace-nowrap text-sm text-gray-400"
  ></p>
</template>

<script setup>
import { useConfigStore } from '../../pinia/modules/config';

const configStore = useConfigStore();

defineProps({
  date: {
    type: String,
    required: true
  }
});
</script>
