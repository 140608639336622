export default {
  created(el, binding) {
    const { countryCode, t } = binding.arg || {
      countryCode: 'DA',
      t: () => {}
    };
    const unformattedDate = binding.value;

    const dateMilliseconds = Date.parse(unformattedDate);
    const nowMilliseconds = Date.now();

    const diffMilliseconds = nowMilliseconds - dateMilliseconds;
    const diffSeconds = Math.floor(diffMilliseconds / 1000);
    const diffMinutes = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);

    const dateFormatted = new Date(dateMilliseconds);
    const dateFormattedString = dateFormatted.toLocaleDateString(countryCode, {
      day: 'numeric',
      month: 'short'
    });
    const dateYearFormattedString = dateFormatted.toLocaleDateString(
      countryCode,
      { day: 'numeric', month: 'short', year: 'numeric' }
    );

    let result;
    if (diffMinutes <= 0) {
      result = t('time.just_now');
    } else if (diffMinutes < 60) {
      result = `${t('time.minutes_ago', { n: diffMinutes })}`;
    } else if (diffHours < 24) {
      result = `${t('time.hours_ago', { n: diffHours })}`;
    } else if (diffDays < 10) {
      result = `${t('time.days_ago', { n: diffDays })}`;
    } else if (diffDays < 365) {
      result = `${dateFormattedString}`;
    } else {
      result = `${dateYearFormattedString}`;
    }

    el.innerText = result;
  }
};
